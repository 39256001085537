import React from 'react';
import { navigate } from 'gatsby';

import WrapperLogo from '../components/layout/wrapperLogo/WrapperLogo';
import WrapperFooter from '../components/layout/footer/wrapperFooter/WrapperFooter';
import ContactInfo from '../components/contact/contactInfo/ContactInfo';
import ContactForm from '../components/contact/contactForm/ContactForm';
import '../components/contact/Contact.scss';

const Contact = () => {
  return (
    <div className="container contact">
      <WrapperLogo active label="Back" onClick={() => navigate('/')} />
      <div className="contact-content flex-box space-around">
        <ContactInfo />
        <ContactForm />
      </div>
      <WrapperFooter />
    </div>
  );
};

export default Contact;
