import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';

import { useWindowWidth } from '../../../services/hooks';
import FormikTextInput from '../../shared/input/formikTextInput/FormikTextInput';
import PrimaryButton from '../../shared/button/PrimaryButton';

const ContactForm = () => {
  const [isChecked, setIsChecked] = useState(false);
  const initialValues = {
    name: '',
    email: '',
    subject: '',
    description: '',
  };
  const windowWidth = useWindowWidth();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('*Required'),
    email: Yup.string().email('*Please enter valid email').required('*Required'),
    subject: Yup.string().required('*Required'),
    description: Yup.string().required('*Required'),
  });

  const handleSubmit = async (values, props) => {
    console.log(values);
  };

  const handleChange = (value) => {
    if (value) setTimeout(() => setIsChecked(true), 2000);
  };

  return (
    <div className="contact-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form className="contact-form-container">
            <div className="contact-form-inputs">
              <div className="flex-box space-between">
                <FormikTextInput
                  errors={props.errors}
                  touched={props.touched}
                  label="Your Name"
                  name="name"
                  placeholder="Enter Your Name"
                  required
                  autoCompleteOff
                  type="text"
                  small={windowWidth >= 992}
                />
                <FormikTextInput
                  errors={props.errors}
                  touched={props.touched}
                  label="Email"
                  name="email"
                  placeholder="Enter Your Email"
                  required
                  autoCompleteOff
                  type="text"
                  small={windowWidth >= 992}
                />
              </div>
              <FormikTextInput
                errors={props.errors}
                touched={props.touched}
                label="Subject"
                name="subject"
                placeholder="Message Subject"
                required
                autoCompleteOff
                type="text"
              />
              <FormikTextInput
                errors={props.errors}
                touched={props.touched}
                label="Message"
                name="description"
                placeholder="Your Message"
                required
                autoCompleteOff
                textarea
                type="text"
              />
            </div>
            {isChecked ? (
              <PrimaryButton
                label="Send"
                large
                gray
                inactive={
                  Object.entries(props.errors).length !== 0 ||
                  Object.entries(props.touched).length < 4
                }
              />
            ) : (
              <ReCAPTCHA sitekey={1} onChange={handleChange} />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;
