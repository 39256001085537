import React from 'react';

const ContactInfo = () => {
  return (
    <div className="contact-info">
      <span className="title head0">Contact Us</span>
      <div className="info-block">
        <span className="title">Phone:</span>
        <span className="title head5 extrabold">(123) 456-7890</span>
      </div>
      <div className="info-block">
        <span className="title">Email:</span>
        <span className="title head5 extrabold underline">support@contactsportal.com</span>
      </div>
      <div className="info-block">
        <span className="title">Working hours:</span>
        <span className="title head5 extrabold">9-5pm EST</span>
      </div>
    </div>
  );
};

export default ContactInfo;
